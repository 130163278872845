
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ActivityVariants',
  data: () => ({
    // username: 'Josh'
  })
})
export default class ActivityVariants extends Vue {
  @Prop() private variants!: any
  /* @Prop() private currentClass!: any
  @Prop() private lesson!: any

  get calculatedMinutes () {
    let mins = 0
    if (this.lesson.activities) {
      mins = this.lesson.activities
        .reduce(function (a: number, b: any) {
          return a + b.duration
        }, 0)
    }
    return mins
  } */
}
