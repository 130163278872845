









































































































import { Component, Vue } from 'vue-property-decorator'
import ActivityDetails from '@/components/ActivityDetails.vue'
import ActivityFinderResults from '@/components/ActivityFinderResults.vue'
import _ from 'lodash'

@Component({
  name: 'ActivityFinder',
  components: {
    ActivityDetails,
    ActivityFinderResults
  },
  data: () => ({
  }),
  methods: {
  },
  computed: {
  }
})
export default class ActivityFinder extends Vue {
  selectedActivity = null
  searchText = ''
  searchMilestone: any = null
  searchStrand: any = null

  get devlevels (): any {
    return this.$store.state.devlevels
  }

  get stagesWithMilestones (): any {
    const items: any[] = this.$store.getters.getStagesWithMilestones() || []
    _.each(items, i => {
      i.selected = this.searchMilestone ? this.searchMilestone.id === i.id : false
    })
    return items
  }

  get strands (): any {
    const items: any[] = this.$store.getters.getStrands() || []
    _.each(items, i => {
      // FOr multiples... i.selected = this.searchStrandIds.find((id: any) => id === i.id)
      i.selected = this.searchStrand ? this.searchStrand.id === i.id : false
    })
    return items
  }

  selectActivity (activity: any) {
    const newActivity = _.cloneDeep(activity)
    this.selectedActivity = newActivity
  }

  get searching (): any {
    this.selectedActivity = null
    let results = this.$store.state.programData.activities
    if (this.searchMilestone) {
      // const searchMilestoneId = this.searchMilestone.id
      // debugger
      const stageMilestoneIds = this.$store.getters.getDevStageById(this.searchMilestone.stageId).milestones.flatMap((item: any) => {
        return item.id
      })
      results = results.filter((item: any) => {
        return _.intersection(stageMilestoneIds, item.milestones).length > 0
        // return searchMilestoneId === item.milestone
      })
    }
    if (this.searchStrand) {
      const searchStrandId = this.searchStrand.id
      results = results.filter((item: any) => {
        // return this.searchStrandIds.indexOf(item.strandId) > -1
        return item.strands && item.strands.find((id: any) => id === searchStrandId)
        // return searchStrandId === item.strand
      })
    }
    if (this.searchText) {
      const search = this.searchText.toLowerCase()
      results = results.filter((item: any) => {
        const text = (item.title || '').toLowerCase()
        return text.indexOf(search) > -1
      })
    }
    return _.sortBy(results, ['title'])
  }

  beforeMount () {
    // debugger
    if (this.$store.getters.getMilestoneOfCurrentClass()) {
      const milestoneId: number = this.$store.getters.getMilestoneOfCurrentClass().id
      this.searchMilestone = this.stagesWithMilestones.find((item: any) => item.id === milestoneId)
    }
  }
}
