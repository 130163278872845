



































































































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import ActivityVariants from '@/components/ActivityVariants.vue'
import _ from 'lodash'

@Component({
  name: 'ActivityDetails',
  components: {
    ActivityVariants
  },
  data: () => ({
    snackbar: false,
    snackText: '',
    timeout: 2000
  }),
  methods: {
    addActivity: function (close) {
      // Add the activity to the current Lesson
      this.$store.commit('addActivityToCurrentLesson', this.$props.activity)
      this.$data.snackbar = true
      this.$data.snackText = 'Activity added to lesson.'
      if (close) {
        // close the navigation drawer, need to emit an event
        this.$emit('close-activity-drawer')
      }
    },
    updateActivity: function () {
      // Update the activity in the current Lesson
      this.$store.commit('updateActivityInCurrentLesson', this.$props.activity)
      this.$data.snackbar = true
      this.$data.snackText = 'Activity updated in lesson.'
    },
    remove: function () {
      // Remove the activity from the current Lesson
      this.$store.commit(
        'removeActivityFromCurrentLesson',
        this.$props.activity
      )
      this.$data.snackbar = true
      this.$data.snackText = 'Activity removed from lesson.'
      this.$emit('remove-activity', this.$props.activity)
    }
  }
})
export default class ActivityDetails extends Vue {
  @Prop() private activity!: any
  @Prop() private mode!: string
  /* @Prop() private lesson!: any */
  carouselIndex = 0

  get selectedActivity () {
    return this.activity
  }

  set selectedActivity (value) {
    this.activity = value
  }

  get descriptionHTML () {
    return _.unescape(this.selectedActivity.description)
  }

  get resourceProgramPath () {
    const programPath = this.$store.getters.getConfig('resourceProgramPath', this.$store.state.currentProgram.id)
    const basePath = this.$store.getters.getConfig('resourceBaseUrl', '')
    const path = `${basePath}/lessonplanner/${programPath}/`
    return path
  }

  get recommendedStructureBlock () {
    let content = ''
    const currentStage = this.$store.getters.getStageOfCurrentClass()
    if (currentStage && currentStage.recommendedStructure) {
      content = decodeURI(currentStage.recommendedStructure)
    }
    return content
  }

  get currentStageTitle () {
    const stage = this.$store.getters.getStageOfCurrentClass()
    return stage ? stage.title : ''
  }

  decodeHtml (html: string) {
    return _.unescape(html)
  }

  milestoneTitle (milestoneId: any) {
    return this.$store.getters.getStageAndMilestoneTitle(milestoneId)
  }

  strandTitle (strandId: any) {
    return this.$store.getters.getStrandById(strandId).title
  }

  resetActivityFinder () {
    // close the navigation drawer, need to emit an event
    this.$emit('clear-selected-activity')
    this.$emit('close-activity-drawer')
  }

  handleCarouselChange (value: any) {
    const videoList = document.getElementsByTagName('video')
    // pause all videos
    _.each(videoList, (v) => {
      v.pause()
    })
  }

  @Watch('activity')
  activityChanged (newVal: any, oldVal: any) {
    if (newVal && oldVal && newVal.id !== oldVal.id) {
      this.$data.carouselIndex = 0
    }
  }
}
