

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import LessonDetails from '@/components/LessonDetails.vue'
import ActivityFinder from '@/components/ActivityFinder.vue'
import ActivityDetails from '@/components/ActivityDetails.vue'

@Component({
  components: {
    LessonDetails,
    ActivityFinder,
    ActivityDetails
  },
  data: () => ({
    mini: true,
    drawerIcon: 'mdi-chevron-left'
  }),
  methods: {
    openDrawer: function () {
      this.$data.mini = !this.$data.mini
      this.$data.drawerIcon = this.$data.mini
        ? 'mdi-chevron-left'
        : 'mdi-chevron-right'
    },
    removeActivity: function (activity) {
      this.$store.commit('resetCurrentActivity')
    },
    closeDrawer: function () {
      this.$data.mini = true
    }
  } /*,
  beforeMount: function () {
    this.$store.commit('setCurrentLesson', this.$store.getters.getLessonById(this.$props.id))
  } */
})
export default class Lesson extends Vue {
  @Prop() private loading!: boolean
  @Prop() private programId!: number
  @Prop() private classId!: number
  @Prop() private lessonId!: number
  @Prop() private error: any

  get currentClass () {
    return this.$store.state.currentClass
  }

  get currentLesson () {
    return this.$store.state.currentLesson
  }

  get currentActivity () {
    return this.$store.state.currentActivity
  }

  get currentActivityId () {
    return this.currentActivity ? this.currentActivity.id : null
  }

  clearSelectedActivity () {
    this.$store.state.currentActivity = null
  }

  created () {
    this.fetchData()
  }

  beforeRouteUpdate (to: string, from: string, next: Function) {
    const resetLesson = to !== from
    this.fetchData()
    next()
  }

  public fetchData (): void {
    // this.error = null
    // this.lesson = this.$store.state.currentLesson
    // this.loading = true
    if (!this.$store.state.dataLoaded) {
      return
    }
    const fetchedLessonId = this.$route.params.lessonId
    this.$store.commit(
      'setCurrentProgram',
      this.$store.getters.getPlannerDataByProgramId(this.$store.state.programId)
    )
    this.$store.commit(
      'setCurrentClass',
      this.$store.getters.getClassById(this.$props.classId)
    )
    this.$store.commit(
      'setCurrentLesson',
      this.$store.getters.getLessonById(this.$props.lessonId)
    )
    this.$store.commit('setCurrentActivity', null)
    // replace `getLesson` with your data fetching util / API wrapper
    // getLesson(fetchedId, (err, lesson) => {
    // make sure this request is the last one we did, discard otherwise
    // if (this.$route.params.id !== fetchedId) return
    // this.loading = false
    /* if (err) {
        this.error = err.toString()
      } else {
        this.lesson = lesson
      } */
    // })
  }
}
