












import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import ActivityListItem from '@/components/ActivityListItem.vue'

@Component({
  name: 'ActivityFinderResults',
  components: {
    ActivityListItem
  }
})
export default class ActivityFinderResults extends Vue {
  @Prop() activities: any
  selectedActivityId: any = null

  selectActivity (activity: any) {
    this.selectedActivityId = activity.id
    this.$emit('select-activity', activity)
  }

  progLevel (activity: any, levelCheck: number) {
    let level = 0
    switch (activity.progressionsLevelId) {
      case 8:
      case '8':
        level = 1
        break
      case 9:
      case '9':
        level = 2
        break
      case 10:
      case '10':
        level = 3
        break
      default:
        level = 3
        break
    }
    return level >= levelCheck ? '' : 'text--lighten-5'
  }
}
