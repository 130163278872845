










































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ActivityListItem from '@/components/ActivityListItem.vue'
import LessonEdit from '@/components/LessonEdit.vue'
import draggable from 'vuedraggable'
import _ from 'lodash'

@Component({
  name: 'LessonDetails',
  components: {
    draggable,
    LessonEdit,
    ActivityListItem
  },
  data: () => ({
    drag: false
  }),
  methods: {
    /* selectActivity: function (activity) {
      const newActivity = _.cloneDeep(activity)
      this.$data.selectedActivity = newActivity
    }, */
  }
})
export default class LessonDetails extends Vue {
  @Prop({ default: null }) selectedActivityId: any

  get canAdd () {
    if (this.$store.state.config.activityLimit) {
      return this.currentLesson.activities.length < this.$store.state.config.activityLimit
    }
    return true
  }

  get focusSkill (): any {
    return this.$store.getters.getSkillById(this.currentLesson.focusSkill) || {}
  }

  get editLesson (): any {
    const newItem = _.cloneDeep(this.currentLesson)
    return newItem
  }

  get currentLesson (): any {
    return this.$store.state.currentLesson
  }

  get currentActivity (): any {
    return this.$store.state.currentActivity
  }

  set currentActivity (value) {
    this.$store.commit('setCurrentActivity', value)
  }

  get calculatedMinutes () {
    let mins = 0
    if (this.currentLesson.activities) {
      mins = this.currentLesson.activities
        .reduce(function (a: number, b: any) {
          return a + b.duration
        }, 0)
    }
    return mins
  }

  get minutesClass () {
    const limit = this.$store.getters.getConfig('lessonTotalMinutesLimit', 30)
    if (limit && this.calculatedMinutes > limit) {
      return 'error--text'
    }
    return ''
  }

  get titleCalculated () {
    const showTitle = this.$store.getters.getConfig('lessonIncludeTitle', false)
    if (showTitle) {
      return this.currentLesson.title
    }
    return `Lesson ${this.currentLesson.id}`
  }

  get startTimeAndOrDate () {
    const showTime = this.$store.getters.getConfig('lessonIncludeStartTime', false)
    const showDate = this.$store.getters.getConfig('lessonIncludeStartDate', false)
    let msg = ''
    if (showTime) {
      msg = msg + this.currentLesson.startTime
    }
    if (showDate) {
      msg = msg + (msg === '' ? '' : ', ')
      msg = msg + this.currentLesson.startDate
    }
    return msg
  }

  get showStartDate () {
    return this.$store.getters.getConfig('lessonIncludeStartDate', false)
  }

  get dragOptions () {
    return {
      animation: 200,
      group: 'activities',
      disabled: false,
      ghostClass: 'ghost'
    }
  }

  private handleChange () {
    console.log('changed')
  }

  private inputChanged (value: any) {
    this.currentLesson.activities = value
  }

  private getComponentData () {
    return {
      on: {
        change: this.handleChange,
        input: this.inputChanged
      },
      attrs: {
        wrap: true
      },
      props: {
        value: this.currentLesson.activities
      }
    }
  }

  addActivity () {
    // Open the ActivityFinder, need to emit an event
    this.$emit('open-activity-drawer')
  }

  selectActivity (activity: any) {
    this.currentActivity = activity
  }
}
