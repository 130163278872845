var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"ml-12",attrs:{"max-height":"100%","height":"100%"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{staticClass:"mt-3",attrs:{"items":_vm.stagesWithMilestones,"item-disabled":"disabled","item-text":"title","item-value":"id","label":"Stage","prepend-icon":"mdi-filter-variant","dense":"","disabled":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stageTitle)+" ")]}}]),model:{value:(_vm.searchMilestone),callback:function ($$v) {_vm.searchMilestone=$$v},expression:"searchMilestone"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":_vm.strands,"item-disabled":"disabled","item-text":"title","item-value":"id","label":"Strand","prepend-icon":"mdi-filter-variant","clearable":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}}]),model:{value:(_vm.searchStrand),callback:function ($$v) {_vm.searchStrand=$$v},expression:"searchStrand"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search for an Activity","prepend-icon":"mdi-magnify","clearable":"","dense":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"600px"}},[_c('ActivityFinderResults',{attrs:{"activities":_vm.searching},on:{"select-activity":_vm.selectActivity}})],1)],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('ActivityDetails',_vm._g({attrs:{"mode":"add","activity":_vm.selectedActivity}},_vm.$listeners))],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }